<template>
  <div class="container box" v-if="this.carHistory">
    <h1 class="is-6 mb-4">Historia eksploatacji</h1>
    <div>
      <div class="tile is-6">
        <div class="tile">Nazwa</div>
        <strong>{{ carHistory.name }}</strong>
      </div>
      <div class="tile is-6">
        <div class="tile">Opis</div>
        <strong>{{ carHistory.description || "brak" }}</strong>
      </div>
      <div class="tile is-6 mb-2">
        <div class="tile">Data</div>
        <strong>{{ carHistory.date }}</strong>
      </div>
      <button
        v-if="carHistory.name.includes('Zamówienie #')"
        class="button is-primary"
        type=" is-primary"
        @click="$router.push({ name: 'Order', params: { id: orderId } })"
      >
        Do zamówienia
      </button>
    </div>
  </div>
  <div v-else>Brak takiego zapisu w systemie</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CarHistory",
  data() {
    return {
      carHistory: {},
    };
  },
  computed: {
    ...mapGetters("clientCars", ["getCarHistory"]),
    orderId: {
      get() {
        return this.carHistory.name.split("#")[1];
      },
    },
  },
  created() {
    try {
      //console.log(this.$route.params);
      this.carHistory = this.getCarHistory(
        this.$route.params.myCarId
      )[0].history.filter((h) => {
        return h.id == this.$route.params.carHistoryId;
      })[0];
      //console.log(this.carHistory);
    } catch (error) {
      //console.log(error);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>